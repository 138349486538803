export const language = {
  en: {
    type: "en",
    page1: {
      title1: "FIND YOUR COURAGE",
      p1: "Move the dot to answer each question.\n At the end of the quiz, we’ll reveal your \nsecret superpower.",
      btn1: "LET’S GO",
    },
    formPage: {
      title1: "BEFORE WE START,\nTELL US ABOUT YOURSELF",
      firstName: "FIRST NAME",
      lastName: "LAST NAME",
      countryCode: "+82", // +60
      mobile: "MOBILE",
      email: "EMAIL (Optional)",
      term: "BY SUBMITTING THIS FORM, YOU AGREE TO RECEIVE RECURRING AUTOMATED PROMOTIONAL AND PERSONALIZED MARKETING TEXT MESSAGES (E.G. CART REMINDERS) FROM COACH AT THE CELL NUMBER USED WHEN SIGNING UP. CONSENT IS NOT A CONDITION OF ANY PURCHASE. REPLY HELP FOR HELP AND STOP TO CANCEL. MSG FREQUENCY VARIES. MSG AND DATA RATES MAY APPLY. VIEW",
      termLink: "TERMS & PRIVACY.",
      btn1: "NEXT",
    },
    quest1: {
      title1:
        "Instead of chatting\none-on-one at parties,\nyou find a way to bring\n everyone together.",
      left: "Couldn’t\nbe me",
      right: "Me to a T",
    },

    quest2: {
      title1:
        "Do you start your day\nwith a to-do list, or \nleave it open to stay\n open to surprises?",
      left: `Love\na list`,
      right: `Send me\nsurprises`,
    },

    quest3: {
      title1:
        "Do you travel to the \nplaces everyone is \nposting, or are you the \nthrow-your-dart-at-a- \nmap type?",
      left: `Follow the\nposts`,
      right: `Pass me\n a dart`,
    },

    quest4: {
      title1:
        "Do you triple-check dress\ncodes before heading to\nevents, or pick your \n’fit intuitively?",
      left: `Go by the\ncodes`,
      right: `Feel it out`,
    },

    quest5: {
      title1:
        "Do you prefer to stick to\n(and get great at) what \nyou know, or explore\nthe unknown?",
      left: `What I \nknow`,
      right: `The great\nunknown`,
    },

    page14: {
      title1: "Screenshot this page to redeem your price, \nin person, at the Seongsu Pop-Up",
      redeem: "REDEEM",
      saveresultvideo: "SAVE RESULT VIDEO",
      shopcollection: "SHOP COLLECTION",
      buttonText: "STAFF REDEEM",
      title2: " HERE’S YOUR\nTREAT",
      instruction: "Don’t tap the button below!\nPresent it to staff to redeem.",
      greeting: "YOU ARE A",
      greeting2: "", // change
      redeemHeader: "HERE’S YOUR\nGIFT",
      confirmText: "CONFIRM",
      confirmedText: "REDEEMED",



    },
  },

  kr: {
    type: "kr",
    page1: {
      title1: "FIND YOUR COURAGE",
      p1: "다음 질문들에 대해 점을 움직여 답변을 입력\n해주세요. 질문을 모두 답변하시면 당신의 숨겨\n진 슈퍼파워를 알려드립니다.",
      btn1: "LET’S GO",
    },
    formPage: {
      title1: "게임 시작 전 당신에\n대해 알려주세요.",
      firstName: "이름",
      lastName: "성",
      countryCode: "+82",
      mobile: "MOBILE",
      // email: "이메일 (선택 사항)",
      term: "상기 제공한 정보를 마케팅 목적의 수집 및 이용에 대해 동의합니다.\n상기 제공한 정보를 마케팅 목적의 수집 및 이용에 대해 동의합니다.\nCOACH는 고객님의 개인정보를 안전하게 보호하고 있습니다. 고객님의\n개인정보는 COACH 프로모션 이벤트 안내 및 고객 조사 외에 다흔 목적\n으로 사용되지 않습니다. 개인정보는 COACH\<a href='contactus@coach.com'>(contactus@coach.com</a>)에 이메일로 신청하여 조회, 변경 또는\n삭제 할 수 있습니다. 개인정보처리방침에 대한 자세한 내용은 코치 코리\n아 홈페이지를 참조하십시오.",
      // termLink: "약관 및 개인정보 보호",
      btn1: "NEXT",
    },
    quest1: {
      title1:
        "모임이나 파티에서\n일대일로 대화하기보다는\n모두와 함께 어울리려고 노\n력한다.",
      left: "네? \n제가요?",
      right: "완전 나다",
    },
    quest2: {
      title1:
        "평소 하루 일과를 시작할\n때 나의 모습은?",
      left: "계획 없는\n날도 계획\n한다",
      right: "즉흥적으로\n움직인다",
    },
    quest3: {
      title1:
        "여행을 할때 \n나의 모습은?",
      left: "미리 서칭한\n핫플레이스\n위주로",
      right: "걸음이 닿는\n대로",
    },
    quest4: {
      title1:
        "출근룩을 정할 때 나의\n모습은?",
      left: "전날\n정해둔다",
      right: "그날 느낌에\n따라",
    },
    quest5: {
      title1:"평소 익숙한 것을 선호하나\n요? 아니면 새로운 것을\n 선호하나요?",
      left: "익숙한\n것이 좋다",
      right: "내가\n선구자다",
    },
    page14: {
      title1: "리워드 수령 4월 5일 오픈하는 코치 성수 \n팝업에서 본 페이지의 스크린 샷을 보여주시면 \n특별한 선물을 드립니다. (2층 모노그래밍 서비스\n를 찾아주세요!)",
      redeem: "선물 수령",
      saveresultvideo: "결과 영상 저장",
      shopcollection: "제품 둘러보기",
      buttonText: "직원 확인",
      title2: "팝업 매장에서 선물을\n수령하실 수 있습니다.",
      instruction: "선물 수령 전 아래 버튼을 누르지 마세요!\n팝업 매장 직원에게 보여주시면 확인 후 선물을\n드립니다.",
      greeting: "당신은",
      greeting2: "입니다!",
      confirmText: "확인 완료",
      confirmedText: "수령 완료",

    },
  },
};

